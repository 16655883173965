const CourseSettingsMenu = [
  {
    heading: "menu",
    route: "/training-program",
    pages: [
      {
        heading: "Add Program",
        route: "/training-program-list/add-program",
        fontIcon: "fas fa-road fs-3></i>",
        svgIcon: "/media/icons/duotune/general/gen028.svg",
      },
      {
        heading: "Advanced Program Report",
        route: "/training-program-list/advanced-program-report",
        fontIcon: "fas fa-road fs-3></i>",
        svgIcon: "/media/icons/duotune/general/gen028.svg",
      },
    ],
  },
];

export default CourseSettingsMenu;
